import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LinkWrapper from '../../components/LinkWrapper';
import OneColumnText from '../BlogPostSections/OneColumnText';

function PodcastOptions(props) {
  const { podcastImage, sectionTitle, _rawSectionText, hostInfo, podcastLinks } = props;

  /* RICH TEXT STYLES */
  const richTextStyles = {
    fontColor: 'primary',
    fontSizes: {
      'small': {base:'13px', md:'11px', lg:'12px', xl:'13px'},
      'normal': {base:'15px', md:'13px', lg:'14px', xl:'15px'},
      'h4': {base:'17px', md:'15px', lg:'16px', xl:'17px'},
      'h3': {base:'19px', md:'17px', lg:'18px', xl:'19px'},
      'h2': {base:'21px', md:'19px', lg:'20px', xl:'21px'},
      'h1': {base:'23px', md:'21px', lg:'22px', xl:'23px'},
    },
    fontWeights: {
      'normal': '400',
      'strong': '600',
    },
    lineHeights: {
      'small': {base:'23px', md:'21px', lg:'22px', xl:'23px'},
      'normal': {base:'25px', md:'23px', lg:'24px', xl:'25px'},
      'h4': {base:'27px', md:'25px', lg:'26px', xl:'27px'},
      'h3': {base:'29px', md:'27px', lg:'28px', xl:'29px'},
      'h2': {base:'31px', md:'29px', lg:'30px', xl:'31px'},
      'h1': {base:'33px', md:'30px', lg:'31px', xl:'33px'},
    },
    letterSpacings: {
      'small': {base:'0px', md:'0px'},
      'normal': {base:'0px', md:'0px'},
      'h4': {base:'0px', md:'0px'},
      'h3': {base:'0px', md:'0px'},
      'h2': {base:'0px', md:'0px'},
      'h1': {base:'0px', md:'opx'},
    },
    textAlign: {base:'left', md:'left'},
    mbSection: {base:'10px', msx:'5px', md:'18px'},
    mbContainers: {base:'15px', md:'15px'},
    hover: {borderColor:'primary'},
    linkFontStyle: 'italic',
    listsLeftMargin: {base:'30px', md:'60px'},
    listsRightMargin: {base:'20px', mds:'10px'},
    listItemBottomMargin: {base:'10px', mds:'10px'},
  };

  return (
    <Flex
      w={{base:'87%', mds:'84%', lgs:'1110px', xl:'1110px'}}
      mx='auto'
      mt={{base:'6.25vw', ms:'6.25vw', msx:'6.25vw', md:'6.25vw', lg:'6.25vw', xl:'90px'}}
      alignItems={{base:'flex-start', lg:'flex-start'}}
    >
      {/* DESKTOP IMAGE */}
      <Box
        w={{md:'45%', mds:'45%', lg:'46%', lgs:'46%', xl:'45%'}}
        mr={{md:'2%', mds:'2%', lg:'4%', lgs:'8%', xl:'10%'}}
        display={{base:'none', lg:'block'}}
      >
        <Box
          maxW="497px"
          maxH="497px"
          // bgColor="red"
        >
          <GatsbyImage
            image={podcastImage.asset.gatsbyImageData}
            alt={'wana podcast image'}
          />
        </Box>
      </Box>

      {/* TEXT */}
      <Flex
        w={{md:'100%', mds:'100%', lg:'50%', lgs:'46%', xl:'45%'}}
        direction="column"
        // justifyContent="center"
        alignItems={{base:'center', lg:'flex-start'}}
      >
        {/* TITLE */}
        <Heading
          color="primary"
          fontFamily="Outfit"
          fontSize={{base:'24px', ms:'28px', md:'29px', mds:'30px', lg:'31px', lgs:'32px', xl:'33px'}}
          fontWeight={{base:'800', md:'800'}}
          lineHeight={{base:'1.3', lg:'1.2'}}
          letterSpacing={{base:'-0.28px', md:'-0.28px'}}
          textAlign={{base:'center', lg:'left'}}
          mb="10px"
        >
          {sectionTitle}
        </Heading>

        {/* TEXT */}
        <OneColumnText _rawOneColumnText={_rawSectionText} styles={richTextStyles} />

        {/* HOSTED BY - PODCAST LINKS */}
        <Flex
          direction={{base:'column', ms:'row'}}
        >
          {/* MOBILE IMAGE */}
          <Box
            display={{base:'block', lg:'none'}}
            w={{base:'100%', ms:'45%', msx:'47.5%'}}
            mr={{base:'0', ms:'2%', msx:'5%'}}
          >
            <Box
              maxW={{base:'312px', ms:'497px'}}
              maxH={{base:'312px', ms:'497px'}}
              // bgColor="red"
              mx={{base:'auto', ms:'0'}}
            >
              <GatsbyImage
                image={podcastImage.asset.gatsbyImageData}
                alt={'wana podcast image'}
              />
            </Box>
          </Box>

          <Box
            w={{base:'100%', ms:'53%', msx:'47.5%', lg:'100%'}}
          >
            {/* HOSTED BY */}
            <Flex
              w={{base:'65vw', ms:'45vw', msx:'38vw', md:'38vw', mds:'38vw', lg:'36vw', lgs:'31.2vw', xl:'449px'}}
              h={{base:'62px', ms:'64px', msx:'66px', md:'68px', mds:'70px', lg:'72px', lgs:'74px', xl:'76px'}}
              bgColor="grayBG"
              borderRadius={{base:'31px', ms:'32px', msx:'33px', md:'34px', mds:'35px', lg:'36px', lgs:'37px', xl:'38px'}}
              justifyContent="center"
              alignItems="center"
              position="relative"
              mx="auto"
              mb={{base:'15px', msx:'17px', md:'17px', mds:'19px', lg:'21px', lgs:'23px', xl:'25px'}}
              mt={{base:'25px', ms:'0'}}
              overflow="hidden"
            >
              <Box
                w={{base:'62px', ms:'64px', msx:'66px', md:'68px', mds:'70px', lg:'72px', lgs:'74px', xl:'76px'}}
                h={{base:'62px', ms:'64px', msx:'66px',md:'68px', mds:'70px', lg:'72px', lgs:'74px', xl:'76px'}}
                borderRadius="full"
                // bgColor="blue"
                position="absolute"
                top="0"
                left="0"
                overflow="hidden"
              >
                <GatsbyImage
                  image={hostInfo.hostImage.asset.gatsbyImageData}
                  alt={'wana podcast image'}
                />
              </Box>

              <Box>
                <Text
                  color="primary"
                  fontSize={{base:'10px', msx:'10px', md:'12px'}}
                  fontWeight={{base:'800', md:'800'}}
                  lineHeight={{base:'18px', msx:'18px', md:'20px'}}
                  letterSpacing={{base:'3.53px', md:'3.53px'}}
                  textAlign={{base:'center'}}
                >
                  {'Hosted by'}
                </Text>

                <Text
                  color="primary"
                  fontSize={{base:'14px', msx:'14px', md:'16px', mds:'18px', lg:'20px', lgs:'22px', xl:'24px'}}
                  fontWeight={{base:'800', md:'800'}}
                  lineHeight={{base:'24px', msx:'24px', md:'26px', mds:'28px', lg:'30px', lgs:'32px', xl:'34px'}}
                  letterSpacing={{base:'-0.18px', md:'-0.18px'}}
                  textAlign={{base:'center'}}
                >
                  {hostInfo.hostName}
                </Text>
              </Box>
            </Flex>

            {/* PODCAST LINKS */}
            <Flex
              flexWrap="wrap"
              justifyContent={{base:'space-evenly'}}
              alignItems="center"
            >
              {podcastLinks.map((option, indx) => {
                return (
                  <Box
                    key={indx}
                    // w={{base:'37vw', ms:'21.1vw', msx:'16.8vw', md:'150px', mds:'184px', lg:'187px', lgs:'190px', xl:'193px'}}
                    w="193px"
                    h="47px"
                    bgColor={"white"}
                    border="1px"
                    borderColor="gray.300"
                    borderRadius="5px"
                    mt={{base:'15px', ms:'24px', msx:'15px', md:'12px', mds:'15px', lg:'18px', lgs:'21px', xl:'24px'}}
                    overflow="hidden"
                  >
                    <LinkWrapper href={option?.externalLink || option?.internallLink?.route}>
                      <Box
                        w="100%"
                        h="100%"
                        overflow="hidden"
                        className="podcast-option"
                      >
                        <GatsbyImage
                          objectFit={option?.externalLink.includes('google') ? "contain":"cover"}
                          image={option.image.asset.gatsbyImageData}
                          alt={'wana podcast image'} 
                        />
                      </Box>
                    </LinkWrapper>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>

      </Flex>

    </Flex>
  );
}

export default PodcastOptions;

