import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';

const isBrowser = typeof window !== "undefined";

function PodcastGuests(props) {
  const { sectionHeader, guestsList } = props;

  const guestsRef = React.useRef(null);
  
  const [guestsListElm, setGuestsListElm] = React.useState(null);

  React.useEffect(() => {
    if (isBrowser) {
      const guestElm = document.getElementById('guests-list');
      setGuestsListElm(guestElm);
    }
  }, []);

  return (
    <Box
      w="100%"
      h="auto"
      position="relative"
      mt={{base:'14vw', ms:'10vw', msx:'9vw', md:'9vw', mds:'9vw', lg:'9vw', lgs:'9vw', xl:'130px'}}
      bgColor="primaryDarker"
    >
      {/* CURVED BG */}
      {/* <Box
        w="100%"
        h="80%"
        position="absolute"
        top="0"
        bgColor="primaryDarker"
        zIndex="-1"
        transform={{base: 'translateY(12.5%)'}}
      >
        <SvgCurve top />
        
        <SvgCurve top={false} />
      </Box> */}

      {/* SVG TOP */}
      <SvgCurve top />

      {/* CONTENT */}
      <Box
        maxW={{base:'87%', msx:'89%', md:'87%', mds:'84%', lgs:'1004px'}}
        zIndex="1"
        mx="auto"
      >
        <Box
          pt={{base:'18vw', ms:'14vw', msx:'12vw', md:'12vw', mds:'12vw', lg:'11vw', lgs:'11vw', xl:'144px'}}
          mb={{base:'6vw', ms:'5vw', msx:'4vw', md:'4vw', mds:'4vw', lg:'3.55vw', lgs:'3.55vw', xl:'51px'}}
        >
          <Heading
            color="white"
            fontFamily="Outfit"
            fontSize={{base:'24px', ms:'36px', md:'28px', mds:'31px', lg:'34px', lgs:'35px', xl:'36px'}}
            fontWeight={{base:'800', md:'800'}}
            lineHeight={{base:'39px', ms:'70px', md:'62px', mds:'65px', lg:'68px', lgs:'69px', xl:'70px'}}
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            textAlign={{base:'center'}}
          >
            {sectionHeader}
          </Heading>
        </Box>

        <Flex
          ref={guestsRef}
          id="guests-list"
          // w={{base:'308px', md:'100%'}}
          direction={{base:'column', msx:'row'}}
          flexWrap={{base:'nowrap', msx:'wrap'}}
          justifyContent={{base:'center', msx:'space-evenly', md:'space-evenly'}}
          alignItems="center"
          pb={{base:'17vw', ms:'12vw', msx:'12.5vw', md:'12vw', mds:'9vw', lg:'7vw', lgs:'5.1vw', xl:'73px'}}
          // overflowX="scroll"
          mx="auto"
          // onTouchEnd={() => {
          //   console.log('onTouchEnd: ', guestsListElm.scrollLeft);
          // }}
        >
          {guestsList.map((guest, indx) => {
            return (
              <GuestCard key={indx} {...guest} />
            );
          })}

          <Box w={{base:'85.5vw', ssm:'308px', msx:'280px', md:'308px'}} />
        </Flex>
      </Box>

      {/* SVG BOTTOM */}
      <SvgCurve top={false} />
    </Box>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'-5px'} : {bottom:'-5px'};

  return (
    <Box
      w="100%"
      h={top ? {base:'2%', ms:'3%', msx:'5%', md:'6%', mds:'8%', lg:'8%', lgs:'12%', xl:'14%'} : {base:'5%', msx:'8%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            // d="M0.28,122.88 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

const GuestCard = (props) => {
  return (
    <Box
      maxW={{base:'308px'}}
      mb={{base:'9vw', ms:'8vw', msx:'3vw', md:'3.5vw', mds:'3.5vw', lg:'3.5vw', lgs:'3.5vw', xl:'50px'}}
    >
      {/* IMAGE */}
      <Box
        w={{base:'85.5vw', ssm:'308px', msx:'280px', md:'308px'}}
        h={{base:'85.5vw', ssm:'308px', msx:'280px', md:'308px'}}
        className="podcast-guest-image"
      >
        <GatsbyImage 
          image={props.guestImage.asset.gatsbyImageData} 
          alt={'wana podcast guest'} 
        />
      </Box>

      {/* NAME */}
      <Text
        maxW="100%"
        mx={{base:'auto', md:'0'}}
        color="white"
        fontSize={{base:'18px', ms:'20px', msx:'16px', md:'16px', mds:'18px', lg:'20px', lgs:'22px', xl:'24px'}}
        fontWeight={{base:'800', md:'800'}}
        lineHeight={{base:'64px', ms:'66px', msx:'62px', md:'62px', mds:'64px', lg:'66px', lgs:'68px', xl:'70px'}}
        letterSpacing={{base:'-0.18px', md:'-0.18px'}}
        textAlign={{base:'center', md:'left'}}
        noOfLines={1}
      >
        {props.guestName}
      </Text>

      {/* TITLE */}
      <Text
        maxW="100%"
        mx={{base:'auto', md:'0'}}
        color="white"
        fontFamily="Outfit"
        fontSize={{base:'12px', ms:'12px', msx:'12px', md:'12px', mds:'13px', lg:'14px', lgs:'15px', xl:'16px'}}
        fontWeight={{base:'800', md:'800'}}
        lineHeight={{base:'27px', ms:'27px', msx:'24px', md:'27px', mds:'28px', lg:'29px', lgs:'30px', xl:'31px'}}
        letterSpacing={{base:'0px', md:'0px'}}
        textAlign={{base:'center', md:'left'}}
        noOfLines={1}
      >
        {props.guestTitle}
      </Text>
    </Box>
  );
};

export default PodcastGuests;
