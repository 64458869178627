import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import SeoComponent from '../components/SeoComponent';
import Hero from '../sections/Hero';
import PodcastOptions from '../sections/PodcastOptions';
import PodcastGuests from '../sections/PodcastGuests';

function WanaListen(props) {
  const { data } = props;
  const tempImg = data.allSanityEffectsSection.nodes[0].mainImage.asset.gatsbyImageData;
  const seoImg = data.sanityPage.seoImage?.image || data.sanityPage.sections?.[0]?.hero?.heroImg?.bgImage;

  return (
    <>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />
      
      <Box 
        // border="1px"
      >
        {/* PAGE SECTIONS FROM SANITY */}
        {data.sanityPage.sections.map((section, indx) => {
          switch (section._type) {
            case 'hero':
              return (
                <Hero 
                  key={section._key}
                  header={section.mainHeader.headerText}
                  subheader={section.accentHeader.headerText}
                  heroBgImg={section.heroImg}
                />
              );
            case 'podcastOptions':
              return (
                <PodcastOptions 
                  key={section._key}
                  {...section}
                  tempImg={tempImg} 
                />
              );
            case 'podcastGuests':
              return (
                <PodcastGuests 
                  key={section._key}
                  {...section}
                  tempImg={tempImg} 
                />
              );  
          }
        })}
      </Box>
    </>
  );
}

export const query = graphql`
  query {
    sanityPage(slug: {current: {eq: "listen"}}) {
      ctaRoute {
        route
      }
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHero {
          _key
          _type
          accentHeader {
            headerText
          }
          mainHeader {
            headerText
          }
          heroImg  {
            bgImage {
              asset {
                url
                gatsbyImageData(placeholder: NONE, fit: FILL, width: 6000)
              }
            }
            contrast
            brightness
          }
        }
        ... on SanityPodcastOptions {
          _key
          _type
          podcastImage {
            asset {
              gatsbyImageData(width: 497, height: 497, fit: MAX, placeholder: NONE)
            }
          }
          sectionTitle
          _rawSectionText
          hostInfo {
            hostImage {
              asset {
                gatsbyImageData(width: 76, height: 76, fit: MAX, placeholder: NONE)
              }
            }
            hostName
          }
          podcastLinks {
            image {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            imageForMobile {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            externalLink
            internallLink {
              route
            }
          }
        }
        ... on SanityPodcastGuests {
          _key
          _type
          sectionHeader
          guestsList {
            guestImage {
              asset {
                gatsbyImageData(width: 308, height: 308, fit: MAX, placeholder: NONE)
              }
            }
            guestName
            guestTitle
          }
        }
      }
    }
    allSanityEffectsSection {
      nodes {
        mainImage {
          asset {
            gatsbyImageData(width: 497, height: 497, placeholder: NONE)
            altText
          }
        }
      }
    }
  }
`;

export default WanaListen;
